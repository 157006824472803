//local storage
const USER_LOCAL_STORAGE_KEY = "userKey";
const USER_TOKEN = "token";

//settings menu items
const PROFILE_KEY = "profileKey";
const LOGOUT_KEY = "logoutKey";
const DASHBOARD_KEY = "dashboardKey";
const LANGUAGES_KEY = "languagesKey";
const EN_KEY = "en";
const FR_KEY = "fr";

//
const ENCRYPT_KEY = "kNJ3*!Xrvd8*KqS>";

export {
  USER_LOCAL_STORAGE_KEY,
  USER_TOKEN,
  DASHBOARD_KEY,
  LOGOUT_KEY,
  PROFILE_KEY,
  ENCRYPT_KEY,
  LANGUAGES_KEY,
  EN_KEY,
  FR_KEY,
};
