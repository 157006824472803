import {
  BellOutlined,
  DashboardOutlined,
  GlobalOutlined,
  LogoutOutlined,
  SearchOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu, Select } from "antd";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { searchForNote } from "../../api/noteService";
import {
  DASHBOARD_KEY,
  EN_KEY,
  FR_KEY,
  LANGUAGES_KEY,
  LOGOUT_KEY,
  PROFILE_KEY,
} from "../../utils/enum";
import { clearLocalStorage } from "../../utils/localStorage";
import translation from "../../utils/translation";
import "./header.scss";

function Header({ handleSelectedNoteId, t }) {
  const [findValues, setFindValues] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const navigate = useNavigate();

  let selectedKeys = [];
  const getItem = (key, icon, label, type, children) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };
  const settingsMenuItems = [
    getItem(
      "mainmenu",
      <SettingOutlined style={{ fontSize: "15px" }} />,
      "",
      "",
      [
        getItem(PROFILE_KEY, <UserOutlined />, t("header.menu.profile")),
        getItem(
          DASHBOARD_KEY,
          <DashboardOutlined />,
          t("header.menu.dashboard")
        ),
        getItem(
          LANGUAGES_KEY,
          <GlobalOutlined />,
          t("header.menu.languages"),
          "",
          [
            getItem(FR_KEY, "", t("header.menu.french")),
            getItem(EN_KEY, "", t("header.menu.english")),
          ]
        ),
        getItem(LOGOUT_KEY, <LogoutOutlined />, t("header.menu.logout")),
      ]
    ),
  ];

  const formatDataToMatchAntDesignSearch = (data) => {
    return data.map((note) => {
      return { value: note.id, label: note.title };
    });
  };

  const handleSearch = (newSearchValue) => {
    if (newSearchValue) {
      searchForNote(newSearchValue)
        .then((result) => {
          setFindValues(formatDataToMatchAntDesignSearch(result));
        })
        .catch((error) => {
          console.log(error);
          setFindValues([]);
        });
    } else {
      setFindValues([]);
    }
  };

  const handleChange = (newIdNote) => {
    setSearchValue(null);
    setFindValues([]);
    handleSelectedNoteId(newIdNote);
  };

  /**
   * logout from the entire site
   */
  const logout = () => {
    clearLocalStorage();
    navigate("/login");
  };

  /**
   * navigate to the selected menu
   * @param {string} key - key of the selected item
   */
  const handleSelectSettingsMenuItems = ({ key }) => {
    switch (key) {
      case LOGOUT_KEY:
        logout();
        break;
      case DASHBOARD_KEY:
        navigate("/dashboard");
        break;
      case PROFILE_KEY:
        toast("en construction");
        break;
      case EN_KEY:
        translation.changeLanguage(EN_KEY);
        break;
      case FR_KEY:
        translation.changeLanguage(FR_KEY);
        break;
      default:
        selectedKeys = [];
        break;
    }
  };

  return (
    <div className="headerContainer">
      <div className="leftHeader">
        <img className="logoContainer" src="/images/logo.png" alt="logo" />
        <strong>Wiki</strong>

        <Select
          showSearch
          bordered={false}
          value={searchValue}
          suffixIcon={<SearchOutlined size="large" />}
          placeholder={t("common.search")}
          size="large"
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          options={findValues}
          className="searchBar"
        />
      </div>
      <div className="accountInfo">
        <UserAddOutlined className="icon" />
        <BellOutlined className="icon" />
        <Menu
          onClick={handleSelectSettingsMenuItems}
          mode="horizontal"
          style={{
            border: "none",
            borderLeft: "solid rgba(150,150,150,0.8) 1px",
          }}
          items={settingsMenuItems}
          selectedKeys={selectedKeys}
        />
      </div>
    </div>
  );
}

export default withTranslation()(Header);
