import React, { useState } from "react";
import Header from "../header/header";
import LeftPanel from "../leftPanel/leftPanel";
import NoteComponent from "../noteComponent/noteComponent";
import "./dashboard.scss";

export default function Dashboard() {
  const [selectedNoteId, setSelectedNoteId] = useState(undefined);
  const handleSelectedNoteId = (key) => {
    setSelectedNoteId(key);
  };

  return (
    <div className="dashboardContainer">
      <Header handleSelectedNoteId={handleSelectedNoteId} />
      <div className="content">
        <LeftPanel handleSelectedNoteId={handleSelectedNoteId} />

        <NoteComponent selectedNoteId={selectedNoteId} />
      </div>
    </div>
  );
}
