import { Button, TextField } from "@mui/material";
import { Spin } from "antd";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../api/usersService";
import { ENCRYPT_KEY, USER_TOKEN } from "../../utils/enum";
import { setLocalStorageItem } from "../../utils/localStorage";
import "./loggedIn.scss";

function LoggedIn({ t }) {
  const [emailOrPseudo, setEmailOrPseudo] = useState("");
  const [password, setPassword] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLoggedIn = () => {
    setIsLoading(true);

    // Encrypt password
    var encryptPassword = CryptoJS.AES.encrypt(
      password,
      ENCRYPT_KEY
    ).toString();

    login({ username: emailOrPseudo, password: encryptPassword })
      .then((response) => {
        setLocalStorageItem(USER_TOKEN, response.token);
        navigate("/dashboard");
        toast("Bonjour");
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setDisableButton(emailOrPseudo && password ? false : true);
  }, [emailOrPseudo, password]);

  return (
    <div className="loggedInContainer">
      <div className="header">
        <h1>Wiki Max</h1>
      </div>
      <div className="loggedInForm">
        <h1>{t("common.connection")}</h1>
        <TextField
          id="emailOrPseudoId"
          label={t("common.email")}
          variant="outlined"
          className="textFieldContainer"
          value={emailOrPseudo}
          onChange={(e) => setEmailOrPseudo(e.target.value)}
        />
        <TextField
          id="pwdId"
          label={t("common.password")}
          variant="outlined"
          className="textFieldContainer"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          loading={isLoading}
          variant="contained"
          className="buttonContainer"
          onClick={() => handleLoggedIn()}
          disabled={disableButton}
        >
          {isLoading ? <Spin /> : t("common.signIn")}
        </Button>
      </div>
    </div>
  );
}

export default withTranslation()(LoggedIn);
