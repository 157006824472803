import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './noteComponentHeader.scss';

function NoteComponentHeader(props) {
  const { handleChangeMode, editMode, noteTitle, isLoading, updatedAt, t } =
    props;

  return (
    <div className="noteHeader">
      {isLoading ? (
        <Skeleton.Button active paragraph={false} size="small" width="20px" />
      ) : (
        <div className="title">{noteTitle}</div>
      )}
      <div>
        {isLoading ? (
          <Skeleton.Avatar
            active
            title={false}
            avatar
            paragraph={false}
            size="small"
          />
        ) : (
          <>
            {t('note.content.lastModification')}
            <strong> {moment(updatedAt).fromNow()}</strong>
            {editMode ? (
              <SaveOutlined
                onClick={handleChangeMode}
                cursor="pointer"
                className="icons"
                style={{ fontSize: '20px' }}
              />
            ) : (
              <EditOutlined
                onClick={handleChangeMode}
                cursor="pointer"
                className="icons"
                style={{ fontSize: '20px' }}
              />
            )}
          </>
        )}
        {/* {editMode ? (
          <SaveOutlined cursor="pointer" style={{ fontSize: "20px" }} />
        ) : (
          <EditOutlined style={{ fontSize: "20px" }} />
        )} */}
      </div>
    </div>
  );
}

export default withTranslation()(NoteComponentHeader);
