import client from "./axios";

/**
 * get all notes sorting in a tree
 * @returns {Object}
 */
const getTreeNote = () => {
  return new Promise((resolve, reject) => {
    client
      .get(`/treenote`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * search for note which are matching the requested {searchValue}
 * @param {String} searchValue
 * @returns
 */
const searchForNote = (searchValue) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/searchfornote?searchvalue=${searchValue}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * create a new note
 * @param {Object} body - required
 * @returns {string}
 */
const createNote = (body) => {
  return new Promise((resolve, reject) => {
    client
      .post(`/note`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * update a note
 * @param {Object} body - required
 * @returns {string}
 */
const updateNote = (body) => {
  return new Promise((resolve, reject) => {
    client
      .put(`/note/id/${body.id}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * given an array of ids, delete the associated notes
 * @param {Object} body - required
 * @returns
 */
const deleteNotesByIds = (body) => {
  return new Promise((resolve, reject) => {
    client
      .delete(`/note`, { data: body })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

/**
 * get a note by its id
 * @param {String} id - required - id of a note
 * @returns
 */
const getNoteById = (id) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/note/id/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject({ message: "Impossible de joindre le serveur" });
        } else {
          reject({ message: "Erreur inconnue" });
        }
      });
  });
};

export {
  getTreeNote,
  createNote,
  updateNote,
  searchForNote,
  getNoteById,
  deleteNotesByIds,
};
