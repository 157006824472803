import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./index.scss";

//translation
import "./utils/translation";

//ant Design css
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "antd/dist/antd.min.css";

//react quill css
import "react-quill/dist/quill.snow.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>
);
