import Parser from 'html-react-parser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import { getNoteById, updateNote } from '../../api/noteService';
import './noteComponent.scss';
import NoteComponentHeader from './noteComponentHeader/noteComponentHeader';

function NoteComponent({ selectedNoteId, t }) {
  const [value, setValue] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [note, setNote] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeMode = () => {
    setEditMode((prevState) => !prevState);

    //send a request only if we edit the body
    if (value !== note.content) {
      const body = {
        content: value,
        last_modified: moment().format(),
        id: note.id,
      };

      updateNote(body)
        .then((note) => {
          setNote(note);
          setValue(note.content);
          toast.success('Note editée');
        })
        .catch((error) => toast.error(error.message));
    }
  };

  /** start region ReactQuill parameters */
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];
  /** end region ReactQuill parameters */

  useEffect(() => {
    if (selectedNoteId) {
      setIsLoading(true);
      getNoteById(selectedNoteId)
        .then((response) => {
          setNote(response);
          setValue(response.content);
        })
        .catch((error) => toast.error(error.message))
        .finally(() => setIsLoading(false));
    }
  }, [selectedNoteId]);

  return (
    <div className="NoteComponentContainer">
      {selectedNoteId ? (
        <>
          <NoteComponentHeader
            isLoading={isLoading}
            noteTitle={note.title}
            updatedAt={note.updatedAt}
            handleChangeMode={handleChangeMode}
            editMode={editMode}
          />
          {editMode ? (
            <div className="content">
              <ReactQuill
                placeholder="Commencer à écrire"
                theme="snow"
                className="textEditor"
                value={value}
                onChange={setValue}
                modules={modules}
                formats={formats}
              />
            </div>
          ) : (
            <div className="content">
              {value ? Parser(value) : t('note.content.emptyNote')}
            </div>
          )}
        </>
      ) : (
        <div className="noContent">{t('note.content.noSelectedNote')}</div>
      )}
    </div>
  );
}
export default withTranslation()(NoteComponent);
