import { Input, Modal, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createNote } from '../../../api/noteService';

function AddNoteModal(props) {
  const { openModal, closeModal, treeData, selectedParentKey, t } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setValue(selectedParentKey);
  }, [selectedParentKey]);

  const onChangeParentNote = (newValue) => {
    setValue(newValue);
  };

  const handleAddnote = () => {
    setIsLoading(true);
    createNote({ title, parent_id: value ? value : null })
      .then(() => {
        setTitle('');
        closeModal();
        toast.success('Note créée');
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      centered
      open={openModal}
      onOk={handleAddnote}
      onCancel={() => closeModal()}
      cancelText="Annuler"
      okText="Créer"
      title={t('note.modal.title')}
      okButtonProps={{ disabled: !title.length }}
      destroyOnClose
      confirmLoading={isLoading}
    >
      {t('note.modal.noteTitleDescription')}
      <Input
        placeholder={t('note.modal.noteTitleDescription')}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      {t('note.modal.description')}
      <Input
        placeholder={t('note.modal.description')}
        maxLength={20}
        showCount
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      {t('note.modal.tag')}
      <Input
        placeholder={t('note.modal.tag')}
        maxLength={20}
        showCount
        disabled
        // onChange={(a) => setTitle(a)}
      />
      {t('note.modal.parentDirectory')}
      <TreeSelect
        showSearch
        style={{
          width: '100%',
        }}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
        }}
        placeholder={t('note.modal.parentDirectoryDescription')}
        allowClear
        treeDefaultExpandAll
        onChange={onChangeParentNote}
        treeData={treeData}
      />
    </Modal>
  );
}

export default withTranslation()(AddNoteModal);
