import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "../components/dashboard/dashboard";
import LoggedIn from "../components/loggedIn/loggedIn";
import PrivateRoute from "./privateRoute";
export default function Router() {
  return (
    <>
      <ToastContainer position="bottom-center" autoClose={3000} theme="dark" />

      <Routes>
        <Route path="*" element={<LoggedIn />} />
        <Route path="/login" element={<LoggedIn />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
      </Routes>
    </>
  );
}
