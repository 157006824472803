import {
  DeleteOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import { Modal, Tree } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  deleteNotesByIds,
  getTreeNote,
  updateNote,
} from '../../api/noteService';
import AddNoteModal from './addNoteModal/addNoteModal';
import './leftPanel.scss';

export default function LeftPanel({ handleSelectedNoteId }) {
  const [showPanel, setShowPanel] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [treeData, setTreeData] = useState([]);

  const [checkable, setCheckable] = useState(false);
  const [checkedNotes, setCheckedNotes] = useState([]);

  const [draggable, setDraggable] = useState(true);

  useEffect(() => {
    refreshTreeNote();
    // eslint-disable-next-line
  }, []);

  const onExpand = (expandedKeysValue) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  /**
   *
   * @param {Array} selectedKeysValue key of the selected node in the tree
   * @param {Object} info selected node
   */
  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
    handleSelectedNoteId(info.node.key);
  };

  /**
   * extand panel or not
   */
  const handleShowPanel = () => {
    setShowPanel((prevState) => !prevState);
  };

  /**
   * format data to match AntDesign tree
   * @param {Array} tree tree containing all note
   * @returns
   */
  const formatTreeToMatchAntDesignTree = (tree) => {
    return tree.map((note) => {
      return {
        ...note,
        key: note.id,
        value: note.id,
        label: note.title,
        icon: <PlusOutlined className="addNote" onClick={() => clickIcon()} />,
        children: formatTreeToMatchAntDesignTree(note.children),
      };
    });
  };

  /**
   * handle when click on "+" icon on tree data
   */
  const clickIcon = () => {
    setOpenModal(true);
  };

  /**
   * close modal and reset parent directory
   */
  const handleCloseModal = () => {
    setSelectedKeys([]);
    refreshTreeNote();
    setOpenModal(false);
  };

  /**
   * change the parent note of the selected note
   */
  const onDrop = ({ node, dragNode }) => {
    //call API only if parent has changed
    if (dragNode.parent_id === node.id) return;

    const body = {
      last_modified: moment().format(),
      id: dragNode.id,
      parent_id: node.id,
    };

    updateNote(body)
      .then(() => {
        refreshTreeNote();
      })
      .catch((error) => console.log('error :>> ', error));
  };

  /**
   * refresh the tree
   */
  const refreshTreeNote = () => {
    getTreeNote()
      .then((tree) => {
        setTreeData(formatTreeToMatchAntDesignTree(tree));
      })
      .catch((error) => toast.error(error.message));
  };

  const handleDeleteNote = () => {
    if (checkable && checkedNotes.length) {
      // setOpenDeleteModal(true);
      showDeleteConfirm();
    } else if (checkable) {
      setCheckable(false);
      setDraggable(true);
      setCheckedNotes([]);
    } else {
      setCheckable(true);
      setDraggable(false);
      setCheckedNotes([]);
    }
  };

  const onCheckNote = (_, info) => {
    setCheckedNotes(info.checkedNodes);
  };

  /*** start of delete section  */
  const { confirm } = Modal;

  /**
   * show delete modal confirmation
   */
  const showDeleteConfirm = () => {
    confirm({
      title: 'Voulez vous supprimez ces notes ?',
      icon: <ExclamationCircleFilled />,
      content: renderText(),
      okText: 'supprimer',
      okType: 'danger',
      cancelText: 'annuler',
      onOk() {
        deleteSelectedNote();
      },
      onCancel() {
        setCheckedNotes([]);
        setCheckable(false);
        setDraggable(true);
        console.log(checkedNotes);
      },
    });
  };

  /**
   * display all note which will be deleted
   * @returns {string} formated note
   */
  const renderText = () => {
    let string = '';
    checkedNotes.forEach((note) => (string += `${note.title} | `));
    return string;
  };

  /**
   * delete all selectedNote in the tree
   */
  const deleteSelectedNote = () => {
    const checkedIds = checkedNotes.map((note) => note.id);
    console.log(checkedIds);
    deleteNotesByIds({ noteIds: checkedIds }).then(() => {
      setCheckable(false);
      setDraggable(true);
      setCheckedNotes([]);
      refreshTreeNote();
    });
  };
  /*** end of delete section */

  return (
    <div className={`${showPanel ? 'maxWidth' : 'minWidth'} leftPanel`}>
      <div className={`leftPanelHeader ${!showPanel && 'leftPanelClose'}`}>
        <DeleteOutlined
          className="icons"
          onClick={() => handleDeleteNote()}
          style={{
            fontSize: '20px',
            color: checkedNotes.length ? 'red' : null,
          }}
        />
        <PlusOutlined
          className="icons"
          onClick={() => clickIcon()}
          style={{ fontSize: '20px' }}
        />
        {showPanel ? (
          <DoubleLeftOutlined
            className="icons"
            onClick={() => handleShowPanel()}
            style={{ fontSize: '20px' }}
          />
        ) : (
          <DoubleRightOutlined
            className="icons"
            onClick={() => handleShowPanel()}
            style={{ fontSize: '20px' }}
          />
        )}
      </div>

      {showPanel && (
        <Tree
          draggable={
            draggable && {
              icon: false,
            }
          }
          showIcon
          showLine
          switcherIcon={<DownOutlined />}
          className="tree"
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          checkable={checkable}
          // checkStrictly
          onCheck={onCheckNote}
          // checkedKeys={checkedNotes}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          treeData={treeData}
          // onDragEnd={onDragEnd}
          // onDragEnter={onDragEnter}
          // onDragLeave={onDragLeave}
          // onDragOver={onDragOver}
          // onDragStart={onDragStart}
          onDrop={onDrop}
        />
      )}

      <AddNoteModal
        openModal={openModal}
        closeModal={handleCloseModal}
        treeData={treeData}
        selectedParentKey={selectedKeys[0]}
      />
    </div>
  );
}
